export default function Home() {
	return (
		<div className="min-h-screen flex flex-col justify-center">
			<section className="h-screen bg-black relative flex items-center justify-center">
				<div className="z-10 relative container px-4 pt-[50dvh] md:pt-[50dvh] mx-auto">
					<div className="flex flex-wrap items-center -mx-4">
						<div className="w-full lg:w-1/2 px-4">
							<span className="text-lg font-bold text-teal-400">
								Established in 2024
							</span>
							<h2 className="max-w-2xl mt-12 mb-12 text-5xl md:text-6xl 2xl:text-8xl text-white font-bold font-heading">
								Reinvent everything about music.
							</h2>
							<p className="mb-12 lg:mb-16 2xl:mb-24 text-md text-gray-100">
								[ 音楽にまつわるすべてを再発明する ]
							</p>
						</div>
					</div>
				</div>
				<div className="absolute z-0 inset-0">
					<img
						className="h-screen w-screen object-cover opacity-80"
						src="/images/welcome/welcome-bg.jpg"
						alt="background"
					/>
				</div>
			</section>
			<section className="relative py-20 2xl:py-40 bg-gray-900 overflow-hidden">
				<div className="relative container px-4 mx-auto">
					<div className="max-w-6xl mx-auto">
						<h2 className="mb-20 text-5xl font-bold font-heading text-white">
							Team
						</h2>
						<div className="flex flex-wrap -mx-5 mb-20">
							<div className="w-full lg:w-1/2 px-5 mb-10 lg:mb-0">
								<div className="bg-gray-800 overflow-hidden">
									<div className="px-12 pt-12 pb-4 text-right">
										<a
											className="inline-block mr-4"
											href="https://www.instagram.com/miyatako_hei/"
											target="_blank"
										>
											<img
												className="h-6"
												src="/images/icons/instagram-w.png"
											/>
										</a>
										<a
											className="inline-block"
											href="https://www.tiktok.com/@miyata_ko_hei"
											target="_blank"
										>
											<img className="h-6" src="/images/icons/tiktok-w.png" />
										</a>
									</div>
									<div className="flex items-center mb-16">
										<img
											className="sm:w-48 sm:h-64 w-40 h-48 object-cover"
											src="/images/welcome/welcome-profile-miyata.jpg"
										/>
										<div className="pl-8 pr-8">
											<h3 className="mb-2 text-2xl text-white font-bold font-heading">
												ミヤタコーヘイ
											</h3>
											<div className="mb-2">
												<p className="text-md text-white">音楽デザイナー</p>
												<p className="text-md text-white">Jazz Cellist</p>
											</div>
											<p className="text-md text-gray-300">
												2歳からピアノ、3歳でチェロをはじめ、幼少期より演奏活動を開始。
												ルールに縛られたクラシック音楽に嫌気がさし、クラシックの反逆者として、そして自由な音楽を求めて音楽デザイナー/即興音楽家として活動。
												音楽にまつわるすべてを再発明すべく、blackadderのプロデュースを担う。
											</p>
										</div>
									</div>
									<button className="w-full py-5 bg-blue-500 hover:bg-blue-600 text-white font-bold transition duration-200">
										<span>プロフィール</span>
										<span className="inline-block ml-4">
											<svg
												className="w-5 h-4"
												width={19}
												height={20}
												viewBox="0 0 19 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M18.7383 1.47293L18.7383 10.93L17.5562 10.93L17.5562 2.89739L0.834948 19.6245L0.00154682 18.7911L16.7228 2.064L9.28125 2.064L9.28125 0.881867L18.1472 0.881866C18.4737 0.881866 18.7383 1.14648 18.7383 1.47293Z"
													fill="white"
												/>
											</svg>
										</span>
									</button>
								</div>
							</div>
							<div className="w-full lg:w-1/2 px-5">
								<div className="bg-gray-800 overflow-hidden">
									<div className="px-12 pt-12 pb-4 text-right">
										<a
											className="inline-block mr-4"
											href="https://www.instagram.com/butter251aug/"
											target="_blank"
										>
											<img
												className="h-6"
												src="/images/icons/instagram-w.png"
											/>
										</a>
										<a
											className="inline-block"
											href="https://www.tiktok.com/@bataojisan"
											target="_blank"
										>
											<img className="h-6" src="/images/icons/tiktok-w.png" />
										</a>
									</div>
									<div className="flex items-center mb-16">
										<img
											className="sm:w-48 sm:h-64 w-40 h-48 object-cover"
											src="/images/welcome/welcome-profile-butter.jpg"
										/>
										<div className="pl-8 pr-8">
											<h3 className="mb-2 text-2xl text-white font-bold font-heading">
												小畑和彰
											</h3>
											<div className="mb-2">
												<p className="text-md text-white">Webエンジニア</p>
												<p className="text-md text-white">ピアニスト</p>
											</div>

											<p className="text-md text-gray-300">
												3歳からクラシックピアノをはじめ、現在は人生を豊かにするための趣味としてジャズ・ポップなどを中心に演奏活動をしている。
												本業はウェブエンジニア。blackadderのWebサービス開発のすべてを担う。
											</p>
										</div>
									</div>
									<button className="w-full py-5 bg-blue-500 hover:bg-blue-600 text-white font-bold transition duration-200">
										<span>プロフィール</span>
										<span className="inline-block ml-4">
											<svg
												className="w-5 h-4"
												width={19}
												height={20}
												viewBox="0 0 19 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M18.7383 1.47293L18.7383 10.93L17.5562 10.93L17.5562 2.89739L0.834948 19.6245L0.00154682 18.7911L16.7228 2.064L9.28125 2.064L9.28125 0.881867L18.1472 0.881866C18.4737 0.881866 18.7383 1.14648 18.7383 1.47293Z"
													fill="white"
												/>
											</svg>
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="py-24 md:py-40 bg-black">
				<div className="container mx-auto px-4">
					<div className="max-w-6xl mx-auto">
						<h2 className="mb-20 text-5xl font-bold font-heading text-white">
							About
						</h2>
					</div>
					<div className="max-w-6xl mx-auto">
						<div
							className="mb-20 relative overflow-hidden"
							style={{ height: 512 }}
						>
							<img
								className="absolute top-0 left-0 w-full h-full object-cover transform hover:scale-105 transition duration-200"
								src="/images/welcome/welcome-about-01.jpg"
							/>
						</div>
						<div className="mb-24 text-base md:text-xl max-w-5xl mx-auto text-center text-slate-200 font-light leading-relaxed">
							<p className="mb-2">
								音楽にまつわるすべてを再発明することをテーマに、私たちはWebと音楽の融合によって新たな価値を創出します。
							</p>
							<p className="mb-2">
								音楽を愛するすべての人々に向けて、最新のテクノロジーと革新的なサービスを提供し、音楽を次の次元へと導きます。
							</p>
							<p className="mb-2">
								私たちは、音楽の持つ力を最大限に引き出し、アーティスト、ファン、そして全ての音楽愛好家が一体となるプラットフォームを構築しています。
							</p>
							<p className="">
								創造性と情熱が交差するこの場所で、音楽の未来を共に描き出しましょう。
							</p>
						</div>
						<div className="flex flex-wrap items-center">
							<div className="w-full md:w-1/2 p-10">
								<div
									className="relative overflow-hidden"
									style={{ height: 496 }}
								>
									<img
										className="absolute top-0 left-0 w-full h-full object-cover transform hover:scale-105 transition duration-200"
										src="/images/welcome/welcome-about-02.jpg"
									/>
								</div>
							</div>
							<div className="w-full md:w-1/2 p-10 text-white">
								<h3 className="mb-16 ont-heading text-4xl md:text-5xl font-bold leading-tight">
									Mission
								</h3>
								<ul>
									<li className="flex items-center -m-2 mb-2">
										<div className="w-auto p-2">
											<div className="flex items-center justify-center w-7 h-7 bg-gray-50 rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={12}
													height={12}
													viewBox="0 0 12 12"
													fill="none"
												>
													<path
														d="M3.87459 10.3313C3.53396 10.3315 3.20728 10.1961 2.96662 9.95501L0.22153 7.21093C-0.0738435 6.91546 -0.0738435 6.43651 0.22153 6.14104C0.516999 5.84567 0.995953 5.84567 1.29142 6.14104L3.87459 8.72422L10.7086 1.89023C11.004 1.59486 11.483 1.59486 11.7785 1.89023C12.0738 2.1857 12.0738 2.66465 11.7785 2.96012L4.78256 9.95501C4.5419 10.1961 4.21523 10.3315 3.87459 10.3313Z"
														fill="#273ACF"
													/>
												</svg>
											</div>
										</div>
										<div className="w-auto p-2">
											<span className="text-lg font-medium">
												すべての音楽家に寄り添ったWebサービスを
											</span>
										</div>
									</li>
									<li className="flex items-center -m-2 mb-2">
										<div className="w-auto p-2">
											<div className="flex items-center justify-center w-7 h-7 bg-gray-50 rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={12}
													height={12}
													viewBox="0 0 12 12"
													fill="none"
												>
													<path
														d="M3.87459 10.3313C3.53396 10.3315 3.20728 10.1961 2.96662 9.95501L0.22153 7.21093C-0.0738435 6.91546 -0.0738435 6.43651 0.22153 6.14104C0.516999 5.84567 0.995953 5.84567 1.29142 6.14104L3.87459 8.72422L10.7086 1.89023C11.004 1.59486 11.483 1.59486 11.7785 1.89023C12.0738 2.1857 12.0738 2.66465 11.7785 2.96012L4.78256 9.95501C4.5419 10.1961 4.21523 10.3315 3.87459 10.3313Z"
														fill="#273ACF"
													/>
												</svg>
											</div>
										</div>
										<div className="w-auto p-2">
											<span className="text-lg font-medium">
												革新的なサービスをリーズナブルな料金で
											</span>
										</div>
									</li>
									<li className="flex items-center -m-2 mb-2">
										<div className="w-auto p-2">
											<div className="flex items-center justify-center w-7 h-7 bg-gray-50 rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={12}
													height={12}
													viewBox="0 0 12 12"
													fill="none"
												>
													<path
														d="M3.87459 10.3313C3.53396 10.3315 3.20728 10.1961 2.96662 9.95501L0.22153 7.21093C-0.0738435 6.91546 -0.0738435 6.43651 0.22153 6.14104C0.516999 5.84567 0.995953 5.84567 1.29142 6.14104L3.87459 8.72422L10.7086 1.89023C11.004 1.59486 11.483 1.59486 11.7785 1.89023C12.0738 2.1857 12.0738 2.66465 11.7785 2.96012L4.78256 9.95501C4.5419 10.1961 4.21523 10.3315 3.87459 10.3313Z"
														fill="#273ACF"
													/>
												</svg>
											</div>
										</div>
										<div className="w-auto p-2">
											<span className="text-lg font-medium">
												あらゆる操作をかんたんに
											</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

import React, { useState } from "react";

const Header = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<nav className="fixed top-0 left-0 right-0 bg-black z-20">
			<div className="flex py-8 px-4 lg:px-8 justify-between items-center">
				<a className="text-4xl text-white font-bold" href="#">
					<img
						className="h-10 mr-2 shadow-lg border-white border inline-block"
						src="/images/logos/blackadder-logo-icon.png"
						alt="Logo"
						width="auto"
					/>
					<img
						className="h-3 inline-block"
						src="/images/logos/blackadder-logo-full-w.svg"
						alt="Logo"
						width="auto"
					/>
				</a>

				<div className="lg:hidden">
					<button className="p-2 navbar-burger" onClick={toggleMenu}>
						<svg
							className="w-10 h-3"
							width={39}
							height={13}
							viewBox="0 0 39 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect width={39} height={2} rx={1} fill="#C4C4C4" />
							<rect x={19} y={11} width={20} height={2} rx={1} fill="#C4C4C4" />
						</svg>
					</button>
				</div>

				{/* Navbar backdrop for mobile */}
				<div
					className={`lg:hidden fixed top-0 left-0 inset-0 bg-gray-800 opacity-0 z-40 transition-opacity duration-300 ${
						isOpen ? "opacity-80" : "opacity-0 pointer-events-none"
					}`}
					onClick={toggleMenu}
				></div>

				{/* Mobile menu */}
				<div
					className={`lg:hidden fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50 transform ${
						isOpen ? "translate-x-0" : "-translate-x-full"
					}`}
					style={{ transition: "transform 0.3s ease-out" }}
				>
					<nav className="relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto">
						<div className="flex items-center mb-16 pr-6">
							<a
								className="ml-10 mr-auto text-2xl text-gray-800 font-bold"
								href="#"
							>
								<img
									className="h-12"
									src="/images/logos/blackadder-logo-icon.png"
									alt="Logo"
									width="auto"
								/>
							</a>
						</div>
						<div>
							<ul>
								<li className="mb-1 px-10">
									<a
										className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl"
										href="#"
									>
										Team
									</a>
								</li>
								<li className="mb-1 px-10">
									<a
										className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl"
										href="#"
									>
										About
									</a>
								</li>
								<li className="mb-1 px-10">
									<a
										className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl"
										href="#"
									>
										Contact
									</a>
								</li>
							</ul>
						</div>
						<div className="mt-auto px-10">
							<div className="pt-6">
								<a
									className="block mb-4 py-4 px-12 text-gray-800 text-center font-bold border border-gray-50 hover:border-gray-100 rounded-full"
									href="#"
								>
									ログイン
								</a>
								<a
									className="block py-4 px-12 text-white text-center font-bold bg-blue-500 hover:bg-blue-600 rounded-full transition duration-200"
									href="#"
								>
									b+に登録
								</a>
							</div>
							<p className="mt-6 mb-4 text-sm text-center">
								<span>
									2024 © blackadder LLC.
									<br />
									All rights reserved.
								</span>
							</p>
						</div>
					</nav>
				</div>

				{/* Desktop sign up button */}
				<div className="hidden lg:block absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
					<ul className="flex items-center text-white space-x-10">
						<li>
							<a className="text-white font-bold text-lg" href="#">
								Team
							</a>
						</li>
						<span>
							<svg
								width={5}
								height={5}
								viewBox="0 0 5 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B" />
							</svg>
						</span>
						<li>
							<a className="text-white font-bold text-lg" href="#">
								About
							</a>
						</li>
						<span>
							<svg
								width={5}
								height={5}
								viewBox="0 0 5 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B" />
							</svg>
						</span>
						<li>
							<a className="text-white font-bold text-lg" href="#">
								Contact
							</a>
						</li>
						<span>
							<svg
								width={5}
								height={5}
								viewBox="0 0 5 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B" />
							</svg>
						</span>
						<li>
							<a className="text-white font-bold text-lg" href="#">
								Login
							</a>
						</li>
					</ul>
				</div>
				<div className="hidden lg:block">
					<a
						className="inline-block px-12 py-4 text-white font-bold border border-gray-200 hover:border-white rounded-full"
						href="#"
					>
						b+に登録
					</a>
				</div>
			</div>
		</nav>
	);
};

export default Header;

import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/Header";

const DefaultLayout: React.FC = () => {
	return (
		<div className="">
			<Header />
			<main>
				<Outlet />
			</main>
			<footer>{/* フッターのコンポーネントを追加 */}</footer>
		</div>
	);
};

export default DefaultLayout;
